<div class="grid-skeleton">
  <div class="w-full grid-inner-skeleton" *ngFor="let _ of [0, 1, 2, 3]">
    <p-skeleton width="100%" height="3rem" />
    <p-skeleton width="100%" height="3rem" />
    <p-skeleton width="100%" height="3rem" />
    <p-skeleton width="100%" height="3rem" />
    <p-skeleton width="100%" height="3rem" />
    <p-skeleton width="100%" height="3rem" />
  </div>
</div>
