<div class="grid-outer" ngClass="{{ passGridName }}">
  <p-treeTable
    #treetable
    [value]="paginatorGridDatas"
    [columns]="headerColumns"
    [rows]="rows"
    [scrollable]="true"
    [totalRecords]="totalRecords"
    [(selection)]="selected"
    selectionMode="multiple"
    ngClass="{{ loading ? '' : 'table-border-active' }}"
    (onSort)="onSort($event)"
  >
    <ng-template pTemplate="header" let-columns let-i="'rowIndex">
      <tr>
        @for (col of columns; track col) {
        <th id="table-head">
          {{ col.header }}
        </th>
        }
      </tr>
      @if(gridFilterAndSorting){
      <tr>
        @for (col of columns; track $index) {
        <th id="table-sort-filter">
          <div class="filter-data">
            @for (sort of gridSortData; track sort; let sortIndex = $index) {
            @if($index === sortIndex){ @if(sort === true){
            <span [ttSortableColumn]="col.field" class="sort-icon"
              ><p-treeTableSortIcon [field]="col.field"
            /></span>
            } } } @for (filter of gridFilterData; track filter; let filterIndex
            = $index) { @if($index === filterIndex){ @if(filter === true){
            <button
              class="filter-icon pi pi-filter"
              (click)="applyFilter($event, $index, col.field)"   
              *ngIf="filter"
            ></button>
            } } } @if (filterIndex == $index && filter) {
            <div class="filter-input-outer" *ngIf="filter">
              <input
                pInputText
                type="text"
                class="input-box"
                (input)="applyColumnFilter($event, col.field)"  
                placeholder="Filter" 
              />
            </div>
            }
          </div>
        </th>
        }
      </tr>
      }
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowNode
      let-rowData="rowData"
      let-columns="columns"
    >
      <tr [ttRow]="rowNode" (click)="handleSelect(rowData)">
        @for (col of columns; track $index) {
        <td>
          <div class="table-data-inner">
            @if($index == 0){
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            } @if($index === statusIndex && isRequiredStatus){
            <span
              class="completion-status"
              [ngClass]="{
                complete: convertStrToNum(rowData[col.field]) === 100,
                excess: convertStrToNum(rowData[col.field]) > 100,
                'in-progress':
                  convertStrToNum(rowData[col.field]) > 0 &&
                  convertStrToNum(rowData[col.field]) < 100,
                'empty-ring': convertStrToNum(rowData[col.field]) === 0
              }"
              [ngStyle]="{
                '--percentage': convertStrToNum(rowData[col.field])
              }"
            >
              <ng-container *ngIf="convertStrToNum(rowData[col.field]) === 100">
                <i class="icon"></i>
              </ng-container>
              <ng-container *ngIf="convertStrToNum(rowData[col.field]) > 100">
                <i class="icon"></i>
              </ng-container>
              <ng-container *ngIf="convertStrToNum(rowData[col.field]) === 0">
                <i class="icon"></i>
              </ng-container>
              <ng-container
                *ngIf="
                  convertStrToNum(rowData[col.field]) > 0 &&
                  convertStrToNum(rowData[col.field]) < 100
                "
              >
                <i class="icon"></i>
              </ng-container>
            </span>
            }@if($index === actionButton && actionButtonRequired){
            <button
              pButton
              [disabled]="rowData[col.field] === false || !isEditItemEnabled"
              class="editButton"
              (click)="onEdit($event)"
            ></button>
            <button
              pButton
              [disabled]="rowData[col.field] === false || !isCancelItemEnabled"
              class="deleteButton"
              (click)="onDelete($event)"
            ></button>
            }
            <ng-container
              *ngIf="
                convertBooleanToString(rowData[col.field]) !== 'true' &&
                convertBooleanToString(rowData[col.field]) !== 'false'
              "
            >
              <a
                (click)="onNodeClick($event, $index)"
                [ngClass]="getClassNameforData(rowData[col.field], $index)"
                >{{ rowData[col.field] }}</a
              >
            </ng-container>
          </div>
        </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <div *ngIf="dataNotFound">
        <div class="data-not-found">{{ dataNotFoundLabel }}</div>
      </div>
    </ng-template>
  </p-treeTable>
</div>
