<p-paginator
  [first]="first"
  [rows]="rows"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="[5, 10, 25, 50, 75]"
  [showCurrentPageReport]="true"
  [showFirstLastIcon]="false"
  (onPageChange)="onPageChange($event)"
  currentPageReportTemplate="{first}-{last} of {totalRecords}"
>
</p-paginator>
